
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.mainSection {
    position: relative;
    &::before {
        content: "";
        left: 50%;
        width: 100vw;
        transform: translateX(-50%);
        height: 100%;
        position: absolute;

        background-image: url("/assets/images/topBusinessQuotesBg.jpg");
        top: 0;
        background-size: cover;
        background-repeat: no-repeat;

        z-index: -1;
    }
}

.categories-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    gap: 30px;
    margin-top: 60px;
}

.category-box {
    width: 251px;
    height: 206px;
    background-color: white;
    border-radius: 5px;
    display: flex;
    flex-direction: column;

    align-items: center;

    a {
        border-radius: 5px;
        margin-top: 17px;
        display: block;
        padding: 10px 37px;
        background-color: #ffc659;
        font-weight: 500;
        transition: all 0.2s ease 0s, transform 0.3s ease 0s;

        &:hover {
            transform: translateY(-5px);
            box-shadow: rgb(255 153 0) 0px 10px 20px -10px;
            background-color: #ff9900;
        }
    }
}
.category-icon {
    :first-child {
        margin-top: 27px;
        margin-bottom: 11px;
        width: 70px;
        height: 70px;
    }
}
